import React, { useEffect } from "react"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core"
import btnimg from "../../../images/Group_1667.svg"
import threeLine from "../../../images/three_line.svg"
import DrawerSide from "../../components/DrawerSide"
import UserDrawerWrapper from "../../Schedule/Drawer/UserDrawerWrapper"
import { ButtonColor } from "../../../helper/ButtonColor"
import { useSelector, useDispatch } from "react-redux"
import { actions } from "../../../redux/slices/manageUserSlice"
import { permissions } from "../../../utils/constants"
import { COLORS } from "../../../utils/theme"
import { Link } from "gatsby"
import ErrorAlert from "../../components/modals/errorAlert"
import Head from "../../components/Head"

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[50],
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))(TableRow)

function ManageUser() {
  const dispatch = useDispatch()

  const manageUserState = useSelector(
    state => state.manageUserReducer.usersData
  )
  const userState = useSelector(state => state.userSlice?.user?.userInfo)

  const [drawerOptions, setDrawerOptions] = React.useState({
    open: false,
    content: "",
    user: null,
  })

  const handleClose = () => {
    setDrawerOptions({ open: false, content: "", user: null })
  }

  const removeSelectedUser = () => {
    dispatch(actions.removeSelectedUser())
  }

  useEffect(() => {
    if (userState?.clubId) {
      dispatch(
        actions.getInvites({
          params: { clubId: userState?.clubId },
        })
      )
    }
  }, [userState])

  return (
    <>
      <Head pageTitle="Manage User" />
      <DrawerSide>
        <ErrorAlert />
        <TableContainer style={{ marginTop: "5%", paddingLeft: "15px" }}>
          <div
            style={{
              width: "100%",
              padding: "0px 50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "550px",
                height: "120px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Link
                to="/venue/settings"
                underline="always"
                style={{ color: "#B4B4BC" }}
              >
                <span style={{ color: "#B4B4BC", fontSize: "15px" }}>
                  Setting
                </span>
              </Link>
              <div
                style={{
                  fontSize: "30px",
                  marginBottom: "5%",
                  display: "flex",
                  marginTop: userState?.permission?.includes(permissions.Admin)
                    ? "20px"
                    : "10px",
                }}
              >
                Manage User
              </div>
            </div>
            {userState?.permission?.includes(permissions.Admin) && (
              <div
                style={{
                  height: "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  paddingBottom: "20px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: `${ButtonColor}`,
                    display: "flex",
                    color: "white",
                    width: "188px",
                    borderRadius: "12px 12px",
                    marginRight: "50px",
                  }}
                  onClick={() => {
                    removeSelectedUser()
                    setDrawerOptions({ open: true, content: "NewUser" })
                  }}
                >
                  <img
                    src={btnimg}
                    style={{ color: "white", height: "9px" }}
                    alt=""
                  />
                  <span style={{ marginLeft: "3%", textTransform: "initial" }}>
                    New User
                  </span>
                </Button>
              </div>
            )}
          </div>
          <div>
            <Table
              aria-label="customized table"
              style={{
                border: "1px solid #80808036",
                borderCollapse: "unset",
                borderRadius: 15,
                borderSpacing: 0,
              }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ borderRadius: "20px 0px 0px 0px" }}>
                    Name
                  </StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Permission</StyledTableCell>
                  <StyledTableCell
                    align="left"
                    style={{ borderRadius: "20px 20px 0px 0px" }}
                  >
                    Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ height: "100%" }}>
                {manageUserState?.map((row, index) => (
                  <StyledTableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderRight: "1px solid #80808036",
                        borderBottom: "1px solid rgb(224 224 224 / 37%)",
                      }}
                    >
                      <Button
                        disabled={
                          !userState?.permission?.includes(permissions.Admin) ||
                          row.id === userState?.userId
                        }
                        style={{ textTransform: "initial" }}
                        onClick={() => {
                          dispatch(actions.selectedUser(row))
                          setDrawerOptions({
                            open: true,
                            content: "NewUser",
                            user: row,
                          })
                        }}
                      >
                        <img src={threeLine} alt="" />
                        <pre style={{ marginLeft: "10px" }}>{`${index + 1} ${
                          row.name
                        }`}</pre>
                      </Button>
                    </TableCell>
                    <StyledTableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #80808036",
                        borderBottom: "1px solid rgb(224 224 224 / 37%)",
                      }}
                    >
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #80808036",
                        borderBottom: "1px solid rgb(224 224 224 / 37%)",
                      }}
                    >
                      {row.permission}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{
                        color:
                          row.inviteStatus !== "accepted" ||
                          row.status !== "Active"
                            ? COLORS.darkGray5
                            : "#45C165",
                      }}
                    >
                      {row.inviteStatus !== "accepted"
                        ? "Invitation sent"
                        : row.status}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
        <UserDrawerWrapper
          handleClose={handleClose}
          open={drawerOptions.open}
          content={drawerOptions.content}
          isEdit={drawerOptions.user}
        />
      </DrawerSide>
    </>
  )
}

export default ManageUser
